import { Children, ReactNode, VFC, useEffect, useState } from 'react'
import SwiperCore, { Scrollbar, Pagination, Navigation, Grid, SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { GridOptions } from 'swiper/types'
import styled from 'styled-components'

import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/grid'

SwiperCore.use([Scrollbar, Pagination, Navigation, Grid]);

const Wrapper = styled.span<{ withCustomNavigation?: boolean }>`
  position: relative;
  display: ${props => props.withCustomNavigation ? 'block' : 'inline'};

  .swiper-grid-column {
    height: 100%;
  }
`

/*
  workaround
  @see https://github.com/nolimits4web/swiper/issues/4413
  @see https://github.com/nolimits4web/swiper/issues/4084
*/

type ItemProps = {
  children: ReactNode
}
const Item = ({ children }: ItemProps) => {
  return <>{children}</>
}

type CarouselComposition = {
  Item: typeof Item
}

export type CarouselProps = {
  children: ReactNode
  withScrollbar?: boolean
  withNavigation?: boolean
  withCustomNavigation?: boolean
  withPagination?: boolean
  slidesPerView?: number
  spaceBetween?: number
  className?: string
  breakpoints?: {
    [width: number]: SwiperOptions
    [ratio: string]: SwiperOptions
  },
  grid?: GridOptions
}

export const CarouselItem = ({ children }) =>
  <SwiperSlide>
    {children}
  </SwiperSlide>

const Carousel: VFC<CarouselProps> & CarouselComposition = ({
  children,
  withScrollbar,
  withNavigation,
  withCustomNavigation,
  withPagination,
  slidesPerView,
  spaceBetween,
  className,
  breakpoints,
  grid,
}) => {
  const [uniqueId, setUniqueId] = useState(null)

  useEffect(() => {
    setUniqueId(`${Math.random()}`.substring(2, 15))
  }, [])

  return <Wrapper className={className || ''} withCustomNavigation={withCustomNavigation}>
    <Swiper
      navigation={withCustomNavigation ? {
        nextEl: `.carousel-custom-next--${uniqueId}`,
        prevEl: `.carousel-custom-prev--${uniqueId}`,
      } : withNavigation || false}
      pagination={withPagination ? { clickable: true } : false}
      scrollbar={withScrollbar ?
        {
          draggable: true,
          hide: false
        } : false}
      slidesPerView={slidesPerView || 'auto'}
      spaceBetween={spaceBetween || 1}
      breakpoints={breakpoints}
      grid={grid}
      loopFillGroupWithBlank
    >
      {withCustomNavigation &&
        <>
          <span className={`carousel-custom-next carousel-custom-next--${uniqueId}`} />
          <span className={`carousel-custom-prev carousel-custom-prev--${uniqueId}`} />
        </>
      }
      {Children.map(children, (child: ReactNode) => (
        <SwiperSlide>{child}</SwiperSlide>
      ))}
    </Swiper>
  </Wrapper>
}

Carousel.Item = Item

export default Carousel
